import React from 'react';
import NextLink from 'next/link';
import { MenuItem as MenuItemType } from 'models/menu-item';
import isPartOfUrl from 'utils/is-part-of-url';
import * as Styled from './mobile-navigation.style';

export type MobileNavigationProps = {
  premium?: boolean;
  menuItems: MenuItemType[];
  locale: string;
  isDark?: boolean;
  activeUrl?: string;
};

const MobileNavigation: React.FC<MobileNavigationProps> = ({ menuItems, locale, isDark, activeUrl, premium }) => {
  return (
    <Styled.Nav $isDark={isDark}>
      <Styled.List>
        {menuItems.map(({ url, label, icon, notPremiumAllowed, premiumAllowed }) => {
          return (premium && premiumAllowed) || (!premium && notPremiumAllowed) ? (
            <Styled.ListItem key={label}>
              <NextLink legacyBehavior href={typeof url === 'object' ? url[locale] : url} passHref>
                <Styled.MenuItem $isActive={isPartOfUrl(typeof url === 'object' ? url[locale] : url, activeUrl)}>
                  <Styled.IconContainer>{icon}</Styled.IconContainer>
                  <Styled.Label>{label}</Styled.Label>
                </Styled.MenuItem>
              </NextLink>
            </Styled.ListItem>
          ) : null;
        })}
      </Styled.List>
    </Styled.Nav>
  );
};

export default MobileNavigation;
