import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { colors, fonts } from 'assets/styles';

export const Nav = styled.nav`
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  color: ${({ $isDark }) => ($isDark ? colors.greyTwo : colors.greyOne)};
  background-color: ${({ $isDark }) => ($isDark ? colors.darkGrey : colors.white)};

  box-shadow: 0 -0.4rem 2.4rem ${rgba(colors.black, 0.08)};
`;

export const List = styled.ul`
  display: flex;
  justify-content: space-between;
`;

export const ListItem = styled.li`
  flex: 1;
`;

export const MenuItem = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.4rem 0.4rem 0.8rem;

  text-decoration: none;
  color: inherit;

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${colors.tint};
    `};
`;

export const IconContainer = styled.span`
  margin-bottom: 0.2rem;
  font-size: 2.4rem;
  line-height: 62.5%;
`;

export const Label = styled.span`
  font-family: ${fonts.primary};
  font-weight: 600;
  font-size: 1.2rem;
  line-height: calc(16 / 12);
`;
